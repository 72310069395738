import React from 'react';

import { Paper } from 'wui/Paper';
import { Show } from 'wui/Show';
import { Container } from 'wui/Container';

import { CoverImage } from '../CoverImage';
import { GroupDetails } from '../GroupDetails';
import { Navigation } from '../Navigation';

import { MobileControls } from './MobileControls';

import classes from './layouts.scss';

interface IMobileLayoutProps {
  groupId: string;

  withImage?: boolean;
}

export function MobileLayout(props: IMobileLayoutProps) {
  const { groupId, withImage } = props;

  return (
    <Paper shadow={false} sideBorders={false} topBottomBorders={false}>
      <Container margin="SP3 auto">
        <MobileControls groupId={groupId} />
      </Container>

      <Show if={withImage}>
        <Container fluid>
          <CoverImage groupId={groupId} />
        </Container>
      </Show>

      <Container margin="SP3 auto">
        <GroupDetails groupId={groupId} />
      </Container>

      <Container
        fluid
        margin="SP3 auto 0"
        className={classes.navigationWrapper}
      >
        <Navigation />
      </Container>
    </Paper>
  );
}

MobileLayout.displayName = 'MobileLayout';
