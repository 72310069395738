import React from 'react';

import { Paper } from 'wui/Paper';
import { Container } from 'wui/Container';
import { Show } from 'wui/Show';

import { ApplicationBreadcrumbs } from '../ApplicationBreadcrumbs';
import { CoverImage } from '../CoverImage';
import { GroupDetails } from '../GroupDetails';
import { Navigation } from '../Navigation';

interface IThumbnailImageLayoutProps {
  groupId: string;
  withImage: boolean;
}

export function ThumbnailImageLayout(props: IThumbnailImageLayoutProps) {
  const { groupId, withImage } = props;

  return (
    <Paper shadow={false} sideBorders={false} topBottomBorders={false}>
      <Container margin="SP3 auto">
        <ApplicationBreadcrumbs />
      </Container>
      <Container margin="SP3 auto SP5" gap="SP5" direction="horizontal">
        <Show if={withImage}>
          <CoverImage groupId={groupId} />
        </Show>

        <GroupDetails groupId={groupId} />
      </Container>
      <Container margin="SP5 auto 0" fluid>
        <Navigation />
      </Container>
    </Paper>
  );
}

ThumbnailImageLayout.displayName = 'ThumbnailImageLayout';
ThumbnailImageLayout.defaultProps = {
  withImage: true,
};
