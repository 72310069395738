import React, {
  ClassicComponentClass,
  ComponentClass,
  FunctionComponent,
} from 'react';

import { useCurrentStateAndParams } from '../hooks';

interface IRouteProps {
  state: string;
  component: ComponentClass | FunctionComponent | ClassicComponentClass;
}

export function Route(props: IRouteProps) {
  const { state, component } = props;

  const current = useCurrentStateAndParams();

  if (!current.state.name) {
    return null;
  }

  if (current.state.name.includes(state)) {
    return React.createElement(component);
  }

  return null;
}

Route.displayName = 'Route';
